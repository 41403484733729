// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$bk-a: #000000;
$rd-a: #bd1007;
$wh-a: #FFFFFF;
$gy-a: #f0f0f0;
$gy-b: #9a9a9a;
$gy-c: #585858;
$gn-a: #e9f1d9;
$gn-b: #9dbe53;
// Color
.c-bk-a {
  color: $bk-a;
}

.c-wh-a {
  color: $wh-a;
}

.c-gy-a {
  color: $gy-a;
}

.c-rd-a {
  color: $rd-a;
}

.c-gy-b {
  color: $gy-b;
}

.c-gy-c {
  color: $gy-c;
}

.c-gn-a {
  color: $gn-a;
}

.c-gn-b {
  color: $gn-b;
}
// Background
.bg-bk-a {
  background-color: $bk-a;
}
.bg-rd-a {
  background-color: $rd-a;
}

.bg-wh-a {
  background-color: $wh-a;
}

.bg-gy-a {
  background-color: $gy-a;
}

.bg-gy-b {
  background-color: $gy-b;
}

.bg-gy-c {
  background-color: $gy-c;
}

.bg-gn-a {
  background-color: $gn-a;
}

.bg-gn-b {
  background-color: $gn-b;
}
