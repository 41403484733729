.table-wp {

  .image {
    width: 75px;
  }

  table {
    margin-bottom: 0;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border: 1px solid $gy-a;

    .selectpicker-wp .dropdown-toggle {
      background-color: $gy-a;
      border: none !important;
      width: 55px;

      .filter-option-inner {
        padding: 0;
      }
    }

    th {
      background-color: $gy-c;
      color: $wh-a;
      font-size: px(12);
      padding: px(15) px(15);
      border-right: 1px solid $gy-a;
      text-transform: uppercase;
      font-weight: 400;
      border-bottom: none !important;
    }
    .btn-download {
      color: $gn-b;
    }
    .btn-order {
      color: $gy-c;
      transition: all 0.2s ease-in-out;
    }
    .btn-modify {
      position: relative;
      text-transform: uppercase;
      font-weight: 400;
      font-size: px(8);
      display: inline-block;
      color: $wh-a;
      line-height: 1em;
      background-color: $gy-c;
      padding: px(3) px(6) px(3) px(14);
      margin-top: px(5);
      transition: all 0.2s ease-in-out;
      .material-icons {
        position: absolute;
        top: 50%;
        left: px(2);
        margin-top: px(-5);
        font-size: px(10);
      }
    }
    .btn-order {
      &:hover,
      &:focus {
        color: $gn-b;
      }
    }
    .btn-modify {
      &:hover,
      &:focus {
        background-color: $gn-b;
        color: $wh-a;
      }
    }
    .price,
    .title-product {
      font-size: px(16);
      line-height: 1.2em;
      font-weight: 600;

    }


    .title-brand {
      font-weight: 700;
      color: $gy-c;
      text-transform: uppercase;
      font-style: normal;
      line-height: 1.2em;
      margin-bottom: px(3);
      display: block;
      font-size: px(11);
    }

    td {
      vertical-align: middle;
      padding: px(15) px(15);
      border-right: 1px solid $gy-a;
      font-size: px(13);
      font-weight: 500;

      &:last-child {
        width: px(50);
        border-right: none;
      }
    }

    tr:nth-child(even) {
      background-color: tint($gy-a,50%);
    }
  }
  @include media-breakpoint-down(md) {
    table .price,
    table .title-product {
      font-size: px(14);
    }
    table .title-brand {
      font-size: px(10);
    }
    &#shopping-card {
      .image {
        display: block;
        padding: 0;
        border: none;
        overflow: hidden;
        width: 0px;
        img {
          width: 0px;
        }
      }
      .single {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    background-color: none;
    border-radius: 0;
    border: none;
    overflow: visible;
    box-shadow: none;
    table .price,
    table .title-product {
      font-size: px(15);
    }
    table .title-brand {
      font-size: px(11);
    }

    &#shopping-card {
      .image {
        border-bottom: 1px solid $gy-a;
        padding: 0;
        width: 100%;
        display: block;
        text-align: center;
        overflow: visible;
        width: auto;
        left: 0;
        &::before {
          display: none;
        }
        img {
          width: auto;
        }
      }
      .single {
        display: block;
      }
    }

    table thead {
      display: none;
    }

    table {
      box-shadow: none;
      border: none;
    }

    table tbody td.image {
      width: 100% !important;
    }

    table tbody td {
      position: relative;
      display: block;
      background-color: $wh-a !important;
      padding: 15px 15px 10px 115px;
      border: none;
      min-height: px(45);
      border-bottom: 1px solid $gy-a;
    }

    table tbody td .btn-order {
      font-size: 20px;
    }

    table tbody tr {
      position: relative;
      overflow: hidden;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      display: block;
      border-bottom: none;
      margin-bottom: px(20);
      &:last-child {
        margin-bottom: 0;
      }
    }

    table tbody tr td:last-child {
      width: 100%;
      padding-top: 10px;
      height: 45px;
    }

    table tbody td:before {
      content: attr(data-label);
      font-size: px(11);
      text-transform: uppercase;
      position: absolute;
      font-weight: 400;
      top: 0;
      left: 0;
      width: 100px;
      padding: px(15) px(20) 0;
      height: 100%;
      background-color: $gy-c;
      text-align: right;
      color: $wh-a;
    }
  }
}
