@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
$font-modal: 'Open Sans', sans-serif;
$color-client: #9dbe53;


// Spinner
.spinner-wp {
	display: block;
	width: 32px;
	height: 32px;
	transition: all 0.2s ease-in-out;
}

.spinner-circle {
	border: 4px rgba(#FFF, 1) solid;
	border-top: 4px $color-client solid;
	border-radius: 50%;
	animation: spCircRot 0.6s infinite linear;
}
@keyframes spCircRot {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
.spinner-wp {
	display: block;
	width: 32px;
	height: 32px;
	transition: all 0.2s ease-in-out;
}

#modal-loader {
	display: none;
	@include position(fixed, 50% null null 50%);
	z-index: 99999;
	width: px(60);
	height: px(60);
	padding: px(12) 0 0 px(11);
	margin: px(-30) 0 0 px(-30);
	background-color: $color-client;
	border-radius: px(60);
	text-align: center;
}

body.modal-loading:before {
	content: "";
	@include position(fixed, 0 null null 0);
	z-index: 9999;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
}

body.modal-loading #modal-loader {
	display: block;
}

// Structure
.modal-header {
	padding-left: px(30);
	padding-right: px(50);
	position: relative;
}
.modal-header .close {
	padding: 0;
	margin: 0;
	position: absolute;
	top: 50%;
	right: px(20);
	font-size: px(24);
	margin-top: px(-15);
	font-weight: 400;
	width: px(30);
	height: px(30);
	background-color: #EEE;
	border-radius: 30px;
}
.modal-title {
	line-height: 1.3em;
	font-weight: 600;
	font-size: px(22);
	color: $color-client;
	font-family: $font-modal;
	> .material-icons {
		vertical-align: middle;
		display: inline-block;
		margin-right: px(8);
		position: relative;
		margin-top: px(-4);
	}
}
.modal-body {
	padding-top: 0;
	padding-bottom: 0;
	display: block;
}
.modal-footer {
	display: block;
	padding: 20px;
}
.modal-footer > :not(:first-child) {
	margin: 0;
}
.modal-footer .modal-title {
	text-align: center;
}

// Row Product added
.row-product {
	position: relative;
	&:after {
		content:"";
		position: absolute;
		width: 33.333333%;
		height: 100%;
		bottom: 0;
		right: px(1);
		background-color: #FDFDFD;
		z-index: 1;
	}
	> * {
		position: relative;
		z-index: 2;
	}
}

// Row Product : left column
.product-added {
	.img-fluid {
		width: 100%;
	}
	.title-brand,
	.title-product,
	.title-price {
		line-height: 1.2em;
	}
	.title-brand {
		font-size: px(12);
		font-weight: 600;
		color: #9a9a9a;
		text-transform: uppercase;
	}
	.title-product {
		margin: px(7) 0 px(9);
		font-weight: 600;
		font-size: px(19);
	}
	.title-price {
		color: #9a9a9a;
		font-size: px(13);
		font-weight: 700;
		color: $bk-a;
		span {
			font-weight: 400;
		}
	}
}

// Row Product : right column
.btn-modal {
	font-family: $font-modal;
	position: relative;
	display: block;
	width: 100%;
	font-size: px(13);
	font-weight: 600;
	padding: px(10) px(40) px(10) px(15);
	transition: all 0.2s ease-in-out;
	.material-icons {
		position: absolute;
		top: 0;
		font-size: px(20);
		right: 0;
		padding-top: px(10);
		height: 100%;
		text-align: center;
		width: px(35);
		background-color: rgba(0,0,0,0.05);
	}
	&.prev {
		background-color: $wh-a;
		color: $color-client;
		&:hover,
		&:focus {
			background-color: #F2F2F2;
		}
	}
	&.next {
		background-color: $color-client;
		color: $wh-a;
		&:hover,
		&:focus {
			background-color: shade($color-client, 5%);
		}
	}
}

// Row : Suggest
.product-item-suggest {
	overflow: hidden;
	position: relative;
	display: block;
	height: 100%;

	.link {
		height: 100%;
		display: flex;
		flex-flow: column nowrap;
		border: 1px solid $gy-a;
		justify-content: space-between;
	}

	.btn-more {
		margin-top: px(3);

		a {
			.material-icons {
				position: absolute;
				top: px(1);
				left: 0;
				font-size: px(18);
			}
			font-size: px(12);
			position: relative;
			padding-left: px(20);
			color: $gn-b;
			text-decoration: underline;
		}
	}

	.title {
		font-size: px(14);
		color: $gy-b;
		line-height: 1.2em;
		font-weight: 600;
		margin-top: px(4);
		margin-bottom: px(8);
	}

	.title-sub {
		text-transform: uppercase;
		line-height: 1.2em;
		font-weight: 400;
		font-size: px(12);
		color: $gy-b;
	}

	.content-wp {
		padding: px(15) px(15) 0;
	}

	.price {
		font-size: px(18);
		font-weight: 700;

		&.old {
			margin: 0;
			line-height: 1em;
			font-size: 70%;
			text-decoration: line-through;
		}
	}

	figure {
		overflow: hidden;
	}

	.col-bottom {
		text-align: center;
		padding: px(15);

		.btn-basket {
			background-color: $color-client;
			color: $wh-a;
			display: block;
			position: relative;
			width: 100%;
			padding: px(8);
			font-size: px(12);
		}
	}

	figure {
		background-color: $bk-a;
	}

	img {
		transition: all 0.2s ease-in-out;
	}

	.link:focus,
	.link:hover {
		img {
			transform: scale(1.05);
			opacity: 0.9;
		}
	}
	@include media-breakpoint-down(lg) {
		.col-bottom {
			.btn-basket {
				padding-left: px(8);

				&:before {
					display: none;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.col-bottom {
			.btn-basket {
				padding-left: px(8);
				font-size: px(11);

				&:before {
					display: none;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.content-wp {}

		.col-bottom {
			.btn-basket {
				padding-left: px(8);
				font-size: px(11);

				&:before {
					display: block;
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.link {
			display: block;
		}

		.content-wp {
			min-height: auto;
		}

		.col-bottom {
			.btn-basket {
				padding-top: px(10);
				padding-bottom: px(8);
				font-size: px(12);

				&:before {
					position: static;
					top: auto;
					left: auto;
					vertical-align: middle;
					margin-top: px(-2);
					margin-right: px(10);
					display: inline-block;
				}
			}
		}
	}
}


@include media-breakpoint-down(md) {
	.modal-title {
		font-size: px(20);
	}
	.row-product {
		&:after {
			height: px(70);
			width: calc(100% - 2px);
		}
	}
	.btn-wp {
		margin-top: px(15);
		padding-bottom: px(15);
		div {
			width: calc(50% - 3px);
			display: inline-block;
			&:first-child {
				padding-right: px(10);
			}
			&:last-child {
				padding-left: px(10);
			}
		}
	}
	.modal-footer {
		display: block;
		padding: px(20) px(15);
	}
}

@include media-breakpoint-down(xs) {
	.modal-footer {
		display: block;
		padding: px(20) px(0);
	}
	.modal-title {
		font-size: px(18);
	}
	.modal-header {
		padding: px(15) px(55) px(15) px(15);
	}
	.modal-title .material-icons {
		display: none;
	}
	.row-product {
		&:after {
			height: px(120);
			width: calc(100% - 2px);
		}
	}
	.btn-wp {
		div {
			display: block;
			width: 100%;
			padding: 0 !important;
		}
	}
	.product-added {
		.title-product {
			font-size: px(17);
		}
	}
}
