// General :: Remove autofill (Chrome)

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
	border: none !important;
	-webkit-text-fill-color: inherit !important;
	-webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
	transition: background-color 5000s ease-in-out 0s;
}
// Input :: Text

.form-control {
	color: $bk-a !important;
	border-color: $bk-a;
	box-shadow: none;
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;

	&:focus {
		border-color: $bk-a;
		box-shadow: none;
		color: $bk-a;
		background: $wh-a;
	}
}

// Label

.label-select {
	text-transform: uppercase;
	font-size: px(12);
	margin-bottom: px(2);
}
.label-form {
	text-transform: uppercase;
	font-size: px(13);
	margin-bottom: px(10);
	font-weight: 700;
}


.form-control-bis {
	display: block;
	color: $gy-c !important;
	border-color: $gy-a;
	box-shadow: none;
	border-radius: 0;
	padding: 15px;
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	border-radius: 0px;
	width: 100%;
	box-shadow: 0 0 10px rgba(0,0,0,0);
	transition: all 0.2s ease-in-out;

	&:focus {
		background: $wh-a;
		box-shadow: 0 0 10px rgba(0,0,0,0.1);
		outline: none;
	}
}

// Input :: Text Placeholder

.form-control.placeholder::placeholder {
	color: $bk-a;
}
// Input :: Checkbox & Radio

.checkboxradio-wp {
	position: relative;
	display: block;

	&.except  > label {
		font-weight: 400 !important;
		color: $bk-a !important;
		text-transform: none !important;
	}

	> label,
	> input {
		cursor: pointer;
	}

	> input {
		position: absolute;
		-moz-opacity: 0;
		opacity: 0;
		filter: alpha(opacity = 0);
	}

	> input[type=checkbox] + label,
	> input[type=radio] + label {
		display: block;
		padding: 0 0 5px 35px;
		font-size: rem(13);
		font-weight: 500;

		text-transform: uppercase;
		color: $bk-a;
		background-image: url("../images/icons/sprite-checkbox_radio.png");
		line-height: 1.3em;
		background-repeat: no-repeat;
		background-size: 22px;
		em {
			text-transform: none;
			font-size: px(13);
			font-weight: 300;
			color: $bk-a !important;
			display: block;
			line-height: 1.1em;
			margin-top: px(3);
		}
	}

	> input[type=radio] + label {
		background-position: 0 -218px;
	}

	> input[type=checkbox] + label {
		background-position: 0 -696px;
	}

	> input[type=radio]:checked + label {
		background-position: 0 0;
		color: $gn-b;
		font-weight: 700;

	}

	> input[type=checkbox]:checked + label {
		background-position: 0 -457px;
		color: $gn-b;
		font-weight: 700;
	}
}

// Input :: Radio ON/OFF

.btn-switch-wp {
	position: relative;
	display: flex;
	align-items: center;

	.label {
		padding-left: 12px;
		color: $bk-a;
		font-weight: 600;
		cursor: pointer;
	}

	.checkbox-toggle {
		position: absolute;
		margin-left: -9999px;
		visibility: hidden;
	}

	.checkbox-toggle + label {
		display: block;
		position: relative;
		cursor: pointer;
		outline: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.checkbox-toggle-round + label {
		margin: 0;
		padding: 2px;
		width: 48px;
		height: 24px;
		background-color: $gy-c;
		border-radius: 60px;
		transition: background 0.2s;
	}

	.checkbox-toggle-round + label:after,
	.checkbox-toggle-round + label:before {
		display: block;
		position: absolute;
		content: "";
	}

	.checkbox-toggle-round + label:after {
		top: 3px;
		left: 3px;
		bottom: null;
		width: 18px;
		height: 18px;
		background-color: $wh-a;
		border-radius: 52px;
		transition: margin 0.2s, background 0.2s;
	}

	.checkbox-toggle-round:checked + label {
		background-color: $gn-b;
	}

	.checkbox-toggle-round:checked + label:after {
		margin-left: 23px;
	}
}


.selectpicker-wp {
	position: relative;

	.bootstrap-select {
		width: 100% !important;
	}

	.filter-option {
		@include ellipsis;
	}

	.dropdown-toggle {
		display: flex;
		align-items: center;
		width: 100%;
		padding-right: px(30) !important;
		@extend .form-control;
		outline: none;
	}

	.dropdown-toggle:focus {
		outline: none !important;
	}

	.dropdown-toggle:after {
		@include position(absolute, 50% 10px null null);
		margin-top: -3px;
		color: $bk-a;
	}

	.bootstrap-select.show .dropdown-toggle:after {
		transform: rotate(-180deg);
	}
}
// Input :: Select Dropdown

.bootstrap-select.btn-group .dropdown-menu.inner {
	display: block;
}

.bootstrap-select.btn-group .dropdown-menu {
	padding: 0;

	li a,
	li a span.text {
		display: block;
	}

	li a {
		display: block;
		padding: px(5) px(15);
	}

	li a {
		outline: none !important;
	}

	li:not(:last-child) {
		border-bottom: 1px solid #f8f9fa;
	}

	li a:focus,
	li a:hover {
		background-color: #f8f9fa;
	}
}

.bootstrap-select .dropdown-menu .inner {
	max-height: 200px !important;
}

.bootstrap-select .dropdown-menu {
	min-width: 100% !important;
}
// Validation :: Error

.parsley-errors-list {
	color: #a94442;
	font-size: px(12);
	margin-top: px(5);
}

.custom-file-input.parsley-error + .custom-file-label,
.form-control.parsley-error {
	border-color: #a94442;
	color: #a94442 !important;
}

.form-control.parsley-error::placeholder {
	color: #a94442;
}


// Form wrapper
.form-wp {
	padding: 25px 30px 35px;
	background-color: $wh-a;
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
	border-radius: 8px;


	.btn-modify {
		position: relative;
		text-transform: uppercase;
		font-weight: 400;
		font-size: px(10);
		display: inline-block;
		color: $wh-a;
		line-height: 1em;
		background-color: $gy-c;
		padding: px(5) px(8) px(6) px(20);
		margin-top: px(5);
		transition: all 0.2s ease-in-out;
		.material-icons {
			position: absolute;
			top: 50%;
			left: px(5);
			margin-top: px(-6);
			font-size: px(10);
		}
		&:hover,
		&:focus {
			background-color: $gn-b;
			color: $wh-a;
		}
	}

	&.except-table {
		padding: 0;
		overflow: hidden;
	}

	&.except-b {
		padding-bottom: 25px;
	}

	.label-bis {
		font-size: px(13);
		color: $bk-a;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 2px;
	}

	.form-control-bis,
	.selectpicker-wp .dropdown-toggle {
		display: block;
		width: 100%;
		border: none;
		border-bottom: 1px solid $gy-b;
		color: $gn-b;
		font-size: px(12);
		padding: 6px 0;
		box-shadow: none !important;
		outline: none !important;
		-webkit-appearance: none;
		border-radius: 0 !important;
	}

	.selectpicker-wp .dropdown-toggle .filter-option {
		color: shade($gy-b, 25%);
		font-style: italic;
		opacity: 1;
		font-size: px(14);
	}

	.selectpicker-wp .dropdown.changed .dropdown-toggle .filter-option {
		font-size: px(13);
		font-style: normal;
		color: $bk-a;
	}

	.selectpicker-wp .dropdown.changed .dropdown-toggle {
		border-bottom: 1px solid $bk-a !important;
	}

	textarea.form-control-bis {
		margin-top: 5px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.form-control-bis.active,
	.form-control-bis:focus {
		border-bottom-color: $bk-a;
		color: $gn-b;
	}

	.form-control-bis::placeholder {
		color: shade($gy-b, 25%);
		font-style: italic;
		opacity: 1;
		font-size: px(13);
	}

	.input-caption {
		font-size: px(12);
		text-decoration: underline;
		font-style: italic;
		color: $gy-c;
		display: block;
		margin-top: px(10);
	}

	.datepicker-wp .ui-datepicker-trigger {
		opacity: 0.4;
		right: 3px;
		margin-top: -10px;
	}

	.datepicker-wp .form-control-bis {
		padding-right: 25px;
	}
	@include media-breakpoint-down(md) {
		&.equal-col-item.order {
			height: auto !important;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 20px 25px !important;
	}
	&.text-wp {
		padding: 25px 30px;
		.title {
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: px(5);
			font-size: px(15);
		}

		li {
			display: block;
			position: relative;
			padding-left: px(10);

			&:before {
				content: "-";
				position: absolute;
				top: 2px;
				left: 0;
			}

			&.col-md-6 {
				padding-left: 25px !important;
			}

			&.col-md-6:before {
				left: 15px;
			}
		}
	}

}
