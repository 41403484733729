// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url("../images/icons/sprite.png");
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1100px;
}

.sprite {
	&.back-top {
		width: 50px;
		height: 50px;
		background-position: 0 0;
	}	&.arrow-prev,
	&.arrow-next {
		width: 50px;
		height: 50px;
	}
	&.arrow-prev {
		background-position: -50px 0;
	}
	&.arrow-next {
		background-position: -100px 0;
	}
	&.facebook {
		background-position: -150px 0;
		width: 20px;
		height: 40px;
	}
	&.shopping-card-big {
		background-position: -170px 0;
		width: 50px;
		height: 40px;
	}
	&.shopping-card-small {
		background-position: -220px 0;
		width: 20px;
		height: 20px;
	}
	&.search {
		width: 30px;
		height: 30px;
		background-position: -250px -1px;
	}
	&.round-1,
	&.round-2,
	&.round-3 {
		width: 80px;
		height: 80px;
	}
	&.round-1 {
		background-position: -280px 0;
	}
	&.round-2 {
		background-position: -360px 0;
	}
	&.round-3 {
		background-position: -440px 0;
	}
}
