// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
}


body.header-small {
	padding-top: px(140);
	#header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		margin-top: px(-83);
		.sprite.shopping-card-big{
			opacity: 0;
		}
		.logo {
			left: 10px;
			top: 8px;
			width: px(50);
		}
	}
	@include media-breakpoint-down(md) {
		padding-top: px(130);
		#header {
			margin-top: px(-90);
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 0;
		#header {
			.sprite.shopping-card-big {
				opacity: 1;
			}
			.logo {
				left: auto;
				top: auto;
				width: auto;
			}
			position: relative;
			top: auto;
			left: auto;
			margin-top: auto;
			margin-top: 0;
		}
	}
}



// Navigation :: Force Display on large device
@include media-breakpoint-up(md) {
	#nav-main {
		display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

#nav-mobile-trigger {
	position: absolute;
	top: px(17);
	right: px(15);

	.txt {
		font-size: px(10);
		margin-top: px(5);
		display: block;
		text-align: center;
		font-weight: 700;
	}
}

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 0;
	background-color: $wh-a;
	box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

#nav-top {
	color: $wh-a;
	padding: px(10) 0;
	background-color: $gn-b;

	.container {
		position: relative;
	}

	.contact {
		display: block;
		text-align: center;
		font-size: px(12);

		strong {
			display: inline-block;
			vertical-align: middle;
			margin-left: px(3);
			font-size: 130%;
		}
	}

	.list {
		text-align: right;
		position: absolute;
		top: px(-2);
		right: px(15);

		li {
			display: inline-block;

			&:not(:first-child):before {
				content: "|";
				font-size: px(11);
				color: $wh-a;
				display: inline-block;
				vertical-align: middle;
			}

			a {
				display: inline-block;
				vertical-align: middle;
				line-height: 1.1em;
				font-size: px(12);
			}
		}
	}
	@include media-breakpoint-down(md) {
		.contact {
			text-align: left;
		}
	}
	@include media-breakpoint-down(xs) {
		.contact {
			text-align: center;
		}

		.list {
			display: none;
		}
	}
}

#nav-main-wp {
	padding: px(0) 0 px(12);

	.col-left {
		display: inline-block;
		vertical-align: bottom;
		width: px(140);

		.logo {
			display: block;
			position: relative;
			top: px(5);
			transition: all .2s ease-in-out;
		}
	}

	.col-middle {
		position: relative;
		vertical-align: bottom;
		display: inline-block;
		padding: 0 px(30);
		width: calc(100% - 250px);

		#search-wp {
			position: relative;

			.input-wp {
				background-color: $gy-a;
				border-radius: px(30);
				display: block;
				border: none;
				width: 100%;
				outline: none;
				box-shadow: none;
				-webkit-appearance: none;
				font-size: px(12);
				padding: px(11) px(60) px(11) px(20);
			}

			.submit-wp {
				position: absolute;
				border-radius: px(30);
				top: 0;
				right: 0;
				background-color: $gn-b;
				text-align: center;
				height: 100%;
				width: px(40);
			}
		}

		#nav-main {
			display: flex;
			margin-top: px(10);

			.show-mobile {
				display: none !important;
			}

			.item {
				flex-grow: 1;
			}

			.link {
				position: relative;
				text-align: left;
				display: block;
				color: $bk-a;
				font-size: px(14);
				font-weight: 600;
			}

			.list-bis {
				padding-right: px(5);

				.link {
					position: relative;
					color: $gn-b;
					display: inline-block;
				}

				.item {
					text-align: center;
					margin: 0 px(5);
					display: inline-block;

					&:not(:first-child):before {
						content: "|";
						margin: 0 px(6) 0 px(-3);
						display: inline-block;
						color: $gn-b;
					}
				}
			}

			.link.active,
			.link:focus,
			.link:hover {
				color: $gn-b;
			}

			.link.active {
				text-decoration: underline;
			}

			.nav-sub {
				padding-top: px(5);

				&:before {
					content: "";
					min-width: 100%;
					display: block;
					position: absolute;
					top: px(0);
					height: px(3);
					background-color: transparent;
					box-shadow: 1px 1px 3px rgba(0,0,0,0.05);
				}

				.item-sub {
					border-bottom: 1px solid tint($gy-a, 40%);
					display: block;
				}

				.link-sub {
					&:before {
						content: ">";
						padding-right: px(8);
					}
					display: block;
					padding: px(15) px(20);
					line-height: 1.2em;
					font-size: px(11.5);
					text-transform: uppercase;
					background-color: $wh-a;
					font-weight: 700;
				}

				.link-sub.active,
				.link-sub:focus,
				.link-sub:hover {
					background-color: tint($gy-a,80%);
				}

				.link-sub.active {
					color: $gn-b;
				}

				.link-sub.active {}
			}
			@include media-breakpoint-down(lg) {
				.link {
					font-size: px(12.5);
				}

				.nav-sub {
					margin-top: px(-2);

					.link-sub {
						font-size: px(11);
						padding: px(10) px(12);
					}
				}

				.list-bis {
					margin-top: px(-3);

					.item {
						&:before {
							font-size: px(11);
							margin: 0 0 0 -10px !important;
						}
					}
				}
			}
			@include media-breakpoint-down(md) {
				.link {}

				.nav-sub {
					.link-sub {
						font-size: px(10.5);
						padding: px(9) px(12);
					}
				}

				.list-bis {
					position: absolute;
					top: px(-46);
					left: px(135);
					margin-top: px(-2);

					.item {
						.link {
							color: $wh-a;
						}

						&:before {
							font-size: px(11) !important;
							color: $wh-a !important;
							margin: 0 0 0 -10px !important;
						}
					}
				}
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
			@include media-breakpoint-down(sm) {}
		}
	}

	.col-right {
		vertical-align: bottom;
		display: inline-block;
		width: px(100);
		text-align: center;

		.shopping-card {
			position: relative;

			.number {
				position: absolute;
				top: px(-5);
				right: px(-10);
				background-color: $gn-b;
				color: $wh-a;
				text-align: center;
				line-height: 1em;
				border-radius: 100%;
				font-size: px(9);
				padding-top: px(2);
				min-width: px(14);
				height: px(14);
				display: block;
			}

			.txt {
				display: block;
				font-size: px(13);
				margin-top: px(2);
				font-weight: 700;
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(8) 0 px(12);

		.col-left {
			width: px(110);
		}

		.col-middle {
			width: calc(100% - 220px);
			padding: 0 px(15);
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(5) 0 px(10);

		.logo {
			top: 3px !important;
		}

		.container {
			position: relative;
		}

		.container > * {
			vertical-align: middle !important;
		}

		.col-left {
			width: px(80);
		}

		.col-right {
			position: absolute;
			top: px(0);
			right: px(60);
		}

		.col-middle {
			display: block;
			padding: 0;
			position: static !important;
			width: 100% !important;

			#search-wp {
				position: absolute;
				top: px(10);
				left: px(110);
				width: calc(100% - 240px);
			}

			#nav-main {
				border: 1px solid $gy-a;
				background-color: tint($gy-a, 90%);
				padding: px(10) px(20);
				margin: 20px 0 10px !important;

				.list-bis {
					position: relative !important;
					top: auto !important;
					left: auto !important;
				}

				.item[data-navsub] {
					.link:after {
						content: "[+]";
						font-size: 70%;
						margin-left: px(5);
						vertical-align: middle;
					}

					&.focus {
						.link:after {
							content: "[-]";
						}
					}
				}

				.item {
					margin: 0 !important;
					display: block !important;

					&:before {
						display: none !important;
					}

					&.focus .nav-sub {
						display: block !important;
					}

					.link {
						width: 100%;
						position: relative;
						padding: px(5);
						font-size: px(13);
						margin: 0;
						display: block !important;
						color: $bk-a !important;
					}
				}

				.nav-sub {
					padding-top: 0;
					border: 1px solid $gy-a;
					border-bottom: none;
					margin: px(5) 0;

					.link-sub {
						background-color: $wh-a !important;
					}
				}

				.nav-sub:before {
					display: none;
				}

				.list-bis {
					.link {
						color: $gn-b !important;
					}
				}
			}
		}

		.col-right {
			width: px(60);

			.shopping-card {
				.number {
					top: 0;
					right: 0;
				}

				.sprite {
					transform: scale(0.8) translateY(5px);
				}

				.txt {
					font-size: px(10);
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(5) 0 0;

		.container {
			padding: 0;
		}

		.col-right {
			margin-top: px(-2);
		}

		#nav-mobile-trigger {
			top: 15px !important;
		}

		.col-left {
			width: px(80);
			padding-left: px(5);
		}

		#search-wp {
			padding: px(10);
			position: relative !important;
			top: auto !important;
			width: 100% !important;
			left: auto !important;
			margin-top: px(10);
			border-top: 1px solid $gy-a;

			.input-wp {
				border-radius: 0 !important;
			}

			.submit-wp {
				border-radius: 0 !important;
				top: px(10) !important;
				right: px(10) !important;
				height: calc(100% - 20px) !important;
			}
		}

		.show-mobile {
			display: none;
		}
	}
}
// Navigation :: Sub

.nav-sub {
	display: none;
	position: relative;
	top: auto;
	left: auto;
	height: auto;
	opacity: 1;
	background-color: $wh-a;
}

li[data-navsub] {
	position: relative;
}
@include media-breakpoint-up(md) {
	li[data-navsub].focus > .nav-sub {
		visibility: visible;
		display: block;
		top: px(30);
		height: auto;
		opacity: 1;
	}

	.nav-sub {
		display: none;
		position: absolute;
		left: px(-15);
		z-index: 99;
		overflow: hidden;
		height: 0;
		min-width: 100%;
		padding: 0;
		opacity: 0;
		background-color: $wh-a;
		transition: all 0.1 ease-in-out;
	}
}


#header-page {
	position: relative;
	padding: px(50) 0;
	text-align: center;
	background: #fbfbfb;
	background: -moz-linear-gradient(left,  #fbfbfb 0%, #f7f7f7 100%);
	background: -webkit-linear-gradient(left,  #fbfbfb 0%,#f7f7f7 100%);
	background: linear-gradient(to right,  #fbfbfb 0%,#f7f7f7 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfbfb', endColorstr='#f7f7f7',GradientType=1 );
	.h-like-a {
		font-size: px(28);
	}
	&:before {
		content:"";
		width: 100%;
		height: px(10);
		position: absolute;
		top: px(-10);
		left: 0;
		background-color: transparent;
		box-shadow: 2px 0 5px rgba(0,0,0,0.3);
	}
	@include media-breakpoint-down(sm) {
		.h-like-a {
			font-size: px(26);
		}
		padding: px(40) 0;
	}
	@include media-breakpoint-down(xs) {
		padding: px(25) 0;
		.h-like-a {
			font-size: px(24);
		}
	}
}
