// Footer

#footer-cta {
	background-color: $gn-b;
	color: $wh-a;
	display: table;
	width: 100%;
	.caption {
		display: table-cell;
		vertical-align: middle;
		width: 40%;
		padding: px(25);
		p {
			margin-bottom: 0;
		}
	}
	.img {
		display: table-cell;
		vertical-align: middle;
		background-image: url(../images/illustrations/bg-footer.jpg);
		background-size: cover;
		height: 100%;
		width: 60%;
		background-position: top center;
		text-align: right;
		padding: px(30);
		.btn-a {
			margin-right: px(10);
		}
	}
	@include media-breakpoint-down(lg) {
		.caption,
		.img {
			width: 50%;
		}
		.img {
			text-align: center;
			.btn-a {
				margin-right: 0;
			}
		}
	}
	@include media-breakpoint-down(xs) {
		display: block;
		.caption,
		.img {
			display: block;
			width: 100%;
		}
	}
}
#footer {
	background-color: $gn-a;
	color: $bk-a;
	padding: px(30) 0;
	.list {
		.item:not(:last-child){
			margin-bottom: px(5);
		}
		.link {
			display: block;
			position: relative;
			padding-left: px(10);
			font-weight: 600;
			font-size: px(13);
			&:before {
				content:"-";
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
	.link-tel {
		text-align: center;
		display: block;
		color: $gn-b;
		font-weight: 700;
		text-transform: uppercase;
		font-size: px(16);
	}
	.link-social {
		margin-top: px(5);
		display: block;
		text-align: center;
		color: $gn-b;
		font-weight: 700;
		> * {
			display: inline-block;
			vertical-align: middle;
		}
		.sprite {
			margin-right: px(10);
		}
	}
	.link-pixell {
		display: block;
		text-align: right;
		font-size: px(12);
		> * {
			display: inline-block;
			vertical-align: middle;
		}
		.txt {
			margin-top: px(14);
		}
	}
	@include media-breakpoint-down(md) {
		.link-pixell {
			font-size: px(11);
			img {
				display: none;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.link-pixell {
			margin-top: px(15);
			text-align: center;
			font-size: px(11);
			img {
				display: inline-block;
			}
		}
		.link-social,
		.link-tel {
			font-size: px(14);
		}
		.link-social {
			.sprite {
				display: none;
			}
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(20) 0;

		.link-tel {
			margin-top: px(10);
		}
		.link-pixell {
			text-align: left;
		}
		.link-social,
		.link-tel {
			text-align: left;
		}
	}
}
