// Body

body {
	overflow: hidden;
	overflow-y: auto;
}

body,
html {
	width: 100%;
	background-color: #fff;
}

html {
	overflow-x: hidden;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;
	margin-bottom: 0 !important;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 10px;
		height: 10px;
		margin-right: 5px;
		background-color: $rd-a;
		border-radius: px(18);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: shade($rd-a, 50%);
	}

	button:focus,
	button:hover {
		background-color: shade($rd-a, 50%);
	}
}
// Components :: WOW

.fadeIn,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp {
	visibility: hidden;
}
// Function :: Equal Col
@include media-breakpoint-down(md) {
	.equal-col-item {
		min-height: 0 !important;
	}
}
// Component :: Google map

.map-wrapper {
	position: relative;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
		height: 400px;
	}

	.figcaption {
		background-color: $wh-a;
		position: absolute;
		top: 100px;
		width: 350px;
		right: -250px;
		display: block;
		padding: 30px;
		box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.1);
	}

	.figcaption .item {
		position: relative;
		padding-left: 35px;
		font-weight: 600;
		line-height: 1.5em;
	}

	.figcaption .item:not(:last-child) {
		margin-bottom: 10px;
	}

	.figcaption .item .icon {
		font-size: px(20);
		position: absolute;
		color: $gn-b;
		top: 50%;
		margin-top: -9px;
		left: 0;
	}
	@include media-breakpoint-down(sm) {
		.map-canvas {
			height: px(200);
		}

		.figcaption {
			border: 1px solid $gy-a;
			border-top: none;
			position: relative;
			top: auto;
			width: 100%;
			padding: 20px;
			right: auto;
			box-shadow: none;
			top: auto;
		}

		.figcaption .item {
			font-size: px(13);
		}
	}
}

.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}
// Slick :: Home

#slick-wp {}

#slick {}

#slick-wp {
	.item {}

	#slick-arrows {
		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {}
	// Animation classes
	// .slick-slide .content							{ opacity: 0; transform: translateY(-30px) scale(1); transition: all 0.2s ease-in-out 0.5s;}
	// .slick-slide.animation .content					{ opacity: 1; transform: translateY(0) scale(1); }
}

.list-logo {
	text-align: center;

	li {
		display: inline-block;
		margin: 0 px(10);
	}
	@include media-breakpoint-down(md) {
		li {
			img {
				width: px(90);
			}
		}
	}
	@include media-breakpoint-down(sm) {
		li {
			img {
				width: px(60);
			}
		}
	}
	@include media-breakpoint-down(xs) {
		li {
			img {
				width: 66.66%;
			}
		}
	}
}

#slick-home-wp {
	position: relative;

	.item {
		position: relative;
		width: 100%;
		display: table;
		table-layout: fixed;

		> * {
			display: table-cell;
			vertical-align: middle;
		}

		.img-wp {
			background-size: cover;
			width: 65%;
			height: px(400);
		}

		.caption-wp {
			padding: px(30) px(15) px(30) px(100);
			position: relative;
			width: 35%;
			background-color: $rd-a;
			color: $wh-a;

			> * {
				position: relative;
				z-index: 2;
			}

			.title {
				font-weight: 700;
				text-transform: uppercase;
				font-size: px(17);
				line-height: 1.1em;
			}

			.title-sub {
				margin-top: px(5);
				font-weight: 300;
				line-height: 1.2em;
				text-transform: uppercase;
				font-size: px(23);
			}

			.btn-wp {
				display: block;
				margin-top: px(30);

				.btn-b {
					text-transform: uppercase;
					border: 1px solid $wh-a;
					font-size: px(15);
					padding: px(10) px(20) px(11);
					transition: all 0.2s ease-in-out;
					color: $wh-a !important;
					&:focus,
					&:hover {
						color: $rd-a;
						background-color: $wh-a;
					}
				}
			}

			&:after {
				content: "";
				position: absolute;
				top: 0;
				height: 100%;
				width: px(75);
				background-color: $rd-a;
				right: px(-40);
				transform: skew(-10deg);
			}
		}
	}

	.arrow-next-wp,
	.arrow-prev-wp {
		position: absolute;
		top: 50%;
		transform: scale(0.9);
		margin-top: px(-25);
	}

	.arrow-prev-wp {
		left: px(20);
	}

	.arrow-next-wp {
		right: px(20);
	}
	@include media-breakpoint-down(lg) {
		.item {
			.img-wp {
				width: 60%;
				height: px(350);
			}

			.caption-wp {
				width: 40%;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.item {
			.caption-wp {
				.title {
					font-size: px(16);
				}

				.title-sub {
					font-size: px(20);
				}

				.btn-wp {
					margin-top: px(15);

					.btn-b {
						font-size: px(13);
						padding: px(5) px(10);
					}
				}
			}

			.img-wp {
				width: 50%;
				height: px(300);
			}

			.caption-wp {
				width: 50%;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.item {
			display: block;

			.caption-wp {
				display: block;
				width: 100%;
				background-color: rgba(189,16,7,0.75);
				z-index: 10;
				padding: px(50) px(75);

				&:after {
					display: none;
				}
			}

			.img-wp {
				display: block;
				height: auto;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		.arrow-prev-wp {
			transform: scale(0.6) translateX(-20px);
		}

		.arrow-next-wp {
			transform: scale(0.6) translateX(20px);
		}
	}
	@include media-breakpoint-down(xs) {
		.item {
			.caption-wp {
				padding: px(40);
			}
		}
	}
}

.category-cta {
	position: relative;
	display: block;
	overflow: hidden;
	background-color: $bk-a;

	.link {
		display: block;
	}

	.caption {
		position: relative;
		z-index: 2;
		height: px(55);
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $gn-b;
		color: $wh-a;
		padding: px(15);

		.title {
			text-align: center;
			font-size: px(15);
			line-height: 1.2em;
			font-weight: 600;
		}
	}

	img {
		transition: all 0.2s ease-in-out;
	}

	.link:focus,
	.link:hover {
		img {
			transform: scale(1.05);
			opacity: 0.9;
		}
	}
	@include media-breakpoint-down(lg) {
		.caption {
			.title {
				font-size: px(14);
			}
		}
	}
	@include media-breakpoint-down(md) {
		.caption {
			height: px(50);

			.title {
				font-size: px(12.5);
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.caption {
			height: px(45);

			.title {
				font-size: px(11.5);
			}
		}
	}
}

.product-item {
	overflow: hidden;
	position: relative;
	display: block;
	height: 100%;

	.link {
		height: 100%;
		display: flex;
		flex-flow: column nowrap;
		border: 1px solid $gy-a;
		justify-content: space-between;
	}

	.btn-more {
		margin-top: px(3);

		a {
			.material-icons {
				position: absolute;
				top: px(1);
				left: 0;
				font-size: px(18);
			}
			font-size: px(12);
			position: relative;
			padding-left: px(20);
			color: $gn-b;
			text-decoration: underline;
		}
	}

	.title {
		font-size: px(14);
		color: $gy-b;
		line-height: 1.2em;
		font-weight: 600;
		margin-top: px(4);
		margin-bottom: px(8);
	}

	.title-sub {
		text-transform: uppercase;
		line-height: 1.2em;
		font-weight: 400;
		font-size: px(12);
		color: $gy-b;
	}

	.content-wp {
		padding: px(15) px(15) 0;
	}

	.price {
		font-size: px(18);
		font-weight: 700;

		&.old {
			margin: 0;
			line-height: 1em;
			font-size: 70%;
			text-decoration: line-through;
		}
	}

	.promo-overlay {
		background-color: $gn-b;
		color: $wh-a;
		display: inline-block;
		position: absolute;
		padding: px(3) px(8) px(2);
		text-transform: uppercase;
		font-weight: 500;
		font-size: px(12);
	}

	figure {
		overflow: hidden;
	}

	.col-bottom {
		text-align: center;
		padding: px(15);

		.btn-a {
			display: block;
			position: relative;
			width: 100%;
			padding-right: px(8);
			padding-left: px(8);
			font-size: px(12);
			padding-left: px(35);

			&:before {
				content: "";
				@extend .sprite;
				@extend .shopping-card-small;
				position: absolute;
				top: 50%;
				left: px(10);
				margin-top: px(-10);
			}
		}
	}

	figure {
		background-color: $bk-a;
	}

	img {
		transition: all 0.2s ease-in-out;
	}

	.link:focus,
	.link:hover {
		img {
			transform: scale(1.05);
			opacity: 0.9;
		}
	}
	@include media-breakpoint-down(lg) {
		.col-bottom {
			.btn-a {
				padding-left: px(8);

				&:before {
					display: none;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.col-bottom {
			.btn-a {
				padding-left: px(8);
				font-size: px(11);

				&:before {
					display: none;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.content-wp {}

		.col-bottom {
			.btn-a {
				padding-left: px(8);
				font-size: px(11);

				&:before {
					display: block;
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.link {
			display: block;
		}

		.content-wp {
			min-height: auto;
		}

		.col-bottom {
			.btn-a {
				padding-top: px(10);
				padding-bottom: px(8);
				font-size: px(12);

				&:before {
					position: static;
					top: auto;
					left: auto;
					vertical-align: middle;
					margin-top: px(-2);
					margin-right: px(10);
					display: inline-block;
				}
			}
		}
	}
}

.blog-item {
	position: relative;
	display: block;
	height: 100%;
	overflow: hidden;
	background-color: $wh-a;

	&.except-brand {
		border: 1px solid $gy-a;

		img {
			opacity: 1 !important;
		}
	}

	&.except {
		background-color: $gy-a;

		.title {
			color: $gy-c !important;
			font-size: px(13.5) !important;
		}

		.link:hover,
		.linkfocus {
			background-color: tint($gy-a, 75%);

			.title {
				color: $gn-b !important;
			}
		}
	}

	.title.except {
		text-transform: uppercase;
		margin: px(10) 0 px(5);
		font-weight: 700;
	}

	figure {
		background-color: $bk-a;
	}

	img {
		transition: all 0.2s ease-in-out;
	}

	.link {
		display: block;
		transition: all 0.2s ease-in-out;
	}

	figure {
		overflow: hidden;
	}

	.link:focus,
	.link:hover {
		img {
			transform: scale(1.05);
			opacity: 0.9;
		}
	}

	.link {
		.col-bottom {
			padding: px(10) px(15);

			.date {
				font-size: px(11);
				text-transform: uppercase;
				color: $gy-b;
				font-weight: 600;
			}

			.title {
				margin-top: px(4);
				color: $gn-b;
				font-size: px(12.5);
				line-height: 1.2em;
				font-weight: 700;
			}
			@include media-breakpoint-down(md) {
				.title {
					font-size: px(12);
				}
			}
			@include media-breakpoint-down(sm) {
				.title {
					font-size: px(13);
				}
			}
		}
	}
}

.category-filter {
	border: 1px solid $gy-a;
	border-bottom: none;
	@include media-breakpoint-down(sm) {
		display: none;
	}

	.title {
		font-size: px(15);
		text-transform: uppercase;
		font-weight: 300;
		margin-bottom: 0;
		background-color: $bk-a;
		color: $wh-a;
		padding: px(10) px(15);
	}

	.title-sub {
		padding: px(8) px(15);
		display: block;
		transition: all 0.2s ease-in-out;
		position: relative;
		font-weight: 500;
		font-size: px(14);
		padding-right: px(20);
		line-height: 1.2em;
		border-bottom: 1px solid $gy-a;

		&:focus,
		&:hover {
			background-color: tint($gy-a, 50%);
		}

		&.open {
			background-color: $gn-b;
			font-weight: 600;
			color: $wh-a;
		}

		.material-icons {
			position: absolute;
			top: 50%;
			right: px(5);
			top: 50%;
			font-size: px(18);
			margin-top: px(-8);
			font-weight: 500;
		}
	}

	.link-sub {
		display: block;
		position: relative;
		padding-left: px(13);
		font-size: px(13);

		&.except {
			text-transform: uppercase;
			font-weight: 700;
			font-size: px(9);
			display: inline-block;
			color: $wh-a;
			background-color: $gy-b;
			padding: px(4) px(6) px(2);
			margin-left: px(13);
			margin-top: px(5);

			&:before {
				display: none;
			}
		}

		&.active {
			font-weight: 600;
		}

		&:before {
			content: "-";
			position: absolute;
			top: px(2);
			left: px(5);
		}
	}

	.title-sub.open .material-icons {
		transform: rotate(90deg);
	}

	.title-sub.open + .list-sub {
		display: block;
		padding: px(10);
		background-color: tint($gy-a, 70%);
		border-bottom: 1px solid $gy-a;
	}

	.list-sub {
		display: none;
	}

	&.except-blog {
		.title-sub {
			padding: px(10) px(15);
			font-size: px(13);
			font-weight: 600;

			&.active {
				background-color: $gn-b;
				color: $wh-a;
			}
		}
	}
}

.filter-btn-mobile {
	display: block;
	width: 100%;
	position: relative;
	text-align: center;
	padding: px(8) px(10);
	background-color: $gn-b;
	color: $wh-a;

	.material-icons {
		display: inline-block;
		vertical-align: middle;
		margin-left: px(10);
	}
}

.td-underline {
	text-decoration: underline;
}

#article-page {
	padding-top: px(50);
	box-shadow: 0 1px 2px inset rgba(0,0,0,0.1);

	.order-wp {
		display: block;
		padding: px(30);
		background-color: tint($gy-a, 50%);
	}

	.toggle-wp {
		margin-top: px(20);

		&.open {
			.title .material-icons {
				transform: rotate(180deg);
			}
		}

		.title {
			text-align: left;
			display: block;
			cursor: pointer;
			padding-bottom: px(7);
			color: $gy-c;
			border-bottom: 1px solid shade($gy-a, 15%);
			width: 100%;
			display: block;
			position: relative;
			padding-right: px(25);

			.material-icons {
				transform: rotate(-90deg);
			}

			h3 {
				margin-bottom: 0;
				font-size: px(14);
				font-weight: 700;
				text-transform: uppercase;
			}

			.material-icons {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				font-size: px(18);
			}
		}

		.toggle-content {
			padding-top: px(10);
			font-weight: 300;
			line-height: 1.25em;
			font-size: px(14);

			a {
				position: relative;
				padding-left: px(20);
				display: block;
				margin-bottom: px(10);
				line-height: 1.2em;

				.material-icons {
					color: $gn-b;
					font-size: px(18);
					position: absolute;
					top: px(0);
					left: 0;
				}
			}
		}
	}

	.price-wp.promo-on {
		.price:first-child {
			font-size: px(13);
			text-decoration: line-through;
		}

		.price:last-child {
			margin-top: px(4);
			color: $gn-b;
		}
	}

	.price-wp {
		padding: 0 px(15);
		font-size: px(18);
		font-weight: 700;
		left: 0;
		text-align: center;

		> * {
			margin: 0;
			line-height: 1em;
			vertical-align: middle;
			display: block;
		}
	}

	.btn-a {
		display: block;
		position: relative;
		text-align: center;
		width: 100%;
		padding-right: px(8);
		line-height: 1.2em;
		padding-left: px(8);
		font-size: px(12);
		padding-left: px(35);

		&:before {
			content: "";
			@extend .sprite;
			@extend .shopping-card-small;
			position: absolute;
			top: 50%;
			left: px(10);
			margin-top: px(-10);
		}

		@include media-breakpoint-down(md) {
			padding-left: px(8);
			&:before {
				display: none;
			}
		}
		@include media-breakpoint-down(sm) {
			padding-left: px(35);
			&:before {
				display: block;
			}
		}
	}

	.selectpicker-wp .dropdown-toggle {
		background-color: $wh-a;
		border: none;
		height: 40px;
		padding-top: px(12);
		list-style: 1em !important;
		display: block;

		.filter-option-inner {
			line-height: 1em !important;
			padding: 0 !important;
		}
	}

	.title-brand {
		text-transform: uppercase;
		font-weight: 700;
		font-size: px(16);
		color: $gy-c;
		margin-bottom: 0;
	}

	.title-product {
		font-weight: 400;
		margin-bottom: px(10);
		margin-top: px(3);
		line-height: 1.1em;
		font-size: px(28);
	}

	.desc {
		font-weight: 300;
		line-height: 1.4em;
		font-size: px(15);
	}

	.content {
		margin: px(25) 0 px(40);
	}

	.label-wp {
		margin-top: px(5);
	}

	.label-wp * {
		display: inline-block;
		vertical-align: middle;
		margin-right: px(5);
	}

	.label-reference {
		font-size: px(12);
		color: $gy-c;
	}

	.label-availablity {
		position: relative;
		color: $wh-a;
		text-transform: uppercase;
		font-weight: 500;
		font-size: px(10);
		padding: px(5) px(10);
		line-height: 1em;
		display: inline-block;
		padding-left: px(19);

		.material-icons {
			font-size: px(10);
			position: absolute;
			top: 50%;
			left: px(6);
			margin-top: px(-5);
		}

		&.ok {
			color: #155724;
			background-color: #d4edda;
		}

		&.ko {
			color: #721c24;
			background-color: #f8d7da;
		}

		&.warning {
			color: #856404;
			background-color: #fff3cd;
		}
	}
	@include media-breakpoint-down(md) {
		.order-wp {
			padding: px(20);
		}
	}
	@include media-breakpoint-down(sm) {
		.order-wp {
			padding: px(30);
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: px(30);
	}
}

#slick-product-wp {
	position: relative;

	.label-promo {
		background-color: $gn-b;
		color: $wh-a;
		position: absolute;
		top: 0;
		left: 0;
		padding: px(4) px(7);
		font-size: px(12);
		z-index: 1;
	}

	img {
		max-width: 100%;
	}

	.zoom-wp {
		img {
			max-width: 100%;
			width: 600px;
		}
	}
}



// Shopping card
.order-aside {
	.selectpicker-wp .dropdown-toggle {
		background-color: $wh-a;
		border: 1px solid $gy-a;

		.filter-option-inner {
			padding: 0 !important;
		}
	}

	.title-main {
    background-color: $gy-c;
    color: $wh-a;
    font-size: px(12);
    padding: px(17) px(15) px(16) px(15);
    border-right: 1px solid $gy-a;
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: none !important;
	}

	.content {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		border: 1px solid $gy-a;
		border-bottom: none;
	}

	.content .item {
		padding-right: px(90);
		position: relative;
		padding: px(13) px(15) px(12);
		border-bottom: 1px solid $gy-a;

		&.except {
			padding: 0;
		}

		.title {
			text-transform: uppercase;
			font-size: px(12);
			margin-bottom: 0;
		}

		&.total {
      padding: px(17) px(15) px(16) px(15);
			background-color: $gy-c;
			color: $wh-a;
      .price {
        top: px(15);
      }
		}

		.price {
			font-size: px(13);
			position: absolute;
			top: px(10);
			right: px(15);
			font-weight: 400;
		}
	}
}
