// Font family
$font-stack: 'Open Sans', sans-serif; // Html

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  transition: opacity 0.1s ease-in-out;
}
// Google font ballback


// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
  font-size: px(22);
  line-height: 1.3em;
  font-weight: 700;
  &.bordered {
    text-align: center;
    &:after {
      content:"";
      display: block;
      margin: px(8) auto;
      width: px(55);
      height: px(4);
      background-color: $gn-b;
    }
  }
}
.h-like-b {
  font-size: px(20);
  line-height: 1.3em;
  font-weight: 700;
}
.h-like-c {
  font-size: px(16);
  line-height: 1.3em;
  font-weight: 700;
  font-style: italic;
}

.title-icon {
  > * {
    display: inline-block;
    vertical-align: middle;
    &.material-icons {
      font-size: px(22);
      width: px(30);
    }
    &.h-like-a {
      width: calc(100% - 35px);
    }
  }
}

@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {}
}
@include media-breakpoint-down(sm) {
  .h-like-a {}
}
@include media-breakpoint-down(xs) {
  .h-like-a {
    font-size: px(20);
  }
  .h-like-b {
    font-size: px(18);
  }
  .h-like-c {
    font-size: px(15);
  }
}
// Paragraph hierarchy :: Bigger to smaller

.p-like-a { line-height: 1.9em;}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .p-like-a {}
}
@include media-breakpoint-down(sm) {
  .p-like-a {}
}
@include media-breakpoint-down(xs) {
  .p-like-a {}
}
